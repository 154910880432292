import { BLC_API } from '../config';
import { _postServer, postToMesServer3 } from './common';
import store from '@/store'
/**-------------------------------------------------------------
 * 
 * @param {*} data
 */
export async function getUser(data) {
    const config = {}
    if (store.state.loginUser && store.state.loginUser.token) {
        config.headers = {}
        config.headers.Authorization = store.state.loginUser.token
    }
    return await _postServer(BLC_API, "/api/PowerControl/GetUser", data, config);
}
// 物流-物品类别
 export async function getLogisticTypes(obj){
    return await postToMesServer3('/api/SenderApi/NewPagingGoodsTypeSelect',obj);
}